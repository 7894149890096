import React, { useContext, useState, useEffect } from 'react';
import { multiStepContext } from '../StepContext';
import '../Styles/StatusPage.css';
import Axios from 'axios';

const StatusPage = () => {
  const apiURL = process.env.REACT_APP_API_URL;
  const {setStep, userData, finalData, setUserData} = useContext(multiStepContext);

  const handleInputChange = (e) => {
    let numericValue = e.target.value.replace(/\D/g, '').trim();
    if (numericValue.length > 13) {
        numericValue = numericValue.slice(0, 13);
    }
    setInputValue(numericValue);
}


  const [inputValue, setInputValue] = useState('');
  const [data, setData] = useState("");
  

 const checkNumber = async () => {

  if(inputValue.length !== 13){
    alert('กรุณากรอกหมายเลขบัตรประชาชนให้ครบ 13 หลัก')
  }else{

    const response = await Axios.get(apiURL+`checkDisabledStatus/${inputValue}`);

    if (response.data.status === "approve") {
        if(response.data.approve_status === "ผ่าน"){
          setUserData({ ...userData, statusId: inputValue }); 
          setStep(10);
        }else if(response.data.approve_status === "ไม่ผ่าน"){
          setUserData({ ...userData, statusId: inputValue, notPassReason: response.data.approve_reason }); 
          setStep(23);
        }
    } 
    else if(response.data.status === "notQualify"){
       setUserData({ ...userData, statusId: inputValue }); 
       setStep(18);
    }
    else if(response.data.status === "notFound"){
       setUserData({ ...userData, statusId: inputValue }); 
       setStep(21);
    }
    else if(response.data.status === "submit"){
       setUserData({ ...userData, statusId: inputValue }); 
       setStep(19);
   }
   else if(response.data.status === "confirm"){
       setUserData({ ...userData, statusId: inputValue }); 
       setStep(20)
   }
    else {
      setStep(18);
    }
  }
 };

  return (
    <div className="status-container">
        <div className="status-info">
            เช็คผลการลงทะเบียน
              <br/>
              <span style={{ fontSize: '16' }}>Check your registeration status</span> 
        </div>
        <div className='status-input'>
          <div className="status-id-con">
              หมายเลขบัตรประชาชน / บัตรสวัสดิการ / บัตรประจําตัวคนพิการ
              <input
                className="national-status"
                type='text'
                placeholder='โปรดกรอกข้อมูล'
                value={inputValue}
                onChange={handleInputChange}
              />
          </div>
        </div>
        <div className='status-next-bt'>
            <button className="status-back-bt" onClick={() => setStep(1)}>ย้อนกลับ</button>
            <button onClick={checkNumber} className="status-condition-button">
                ต่อไป
            </button>
        </div>
    </div>
  );
};

export default StatusPage;

import React, { useContext, useState, useEffect } from 'react';
import { multiStepContext } from '../StepContext';
import '../Styles/PortalPage.css'
import nbtcLogo from '../assets/nbtc-logo.png';
import registerLogo from '../assets/register-logo.png';
import statusLogo from '../assets/status-logo.png';
import depLogo from '../assets/dep_logo.gif'
import infiniteLogo from '../assets/INFINITE_LOGO_FIT.png'
import PortalFooter from '../tools/PortalFooter';
import axios from 'axios';

const PortalPage = () => {
    const apiURL = process.env.REACT_APP_API_URL;
    const { setStep, userData, setUserData, setStartTime, setEndTime } = useContext(multiStepContext);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    const handleCampaignStatus = async () => {
        try {
            const response = await axios.get(`${apiURL}campaignStatusDisabled`);
            const status = response.data.status;

            if (response.data.startTime && response.data.endTime) {
                setStartTime(response.data.startTime);
                setEndTime(response.data.endTime);
            }

            if (status === 'Not Live') {
                setStep(24);
            } else if (status === 'Open') {
                setStep(2);
            } else if (status === 'Close') {
                setStep(25);
            } else if (status === 'Maintenance') {
                setStep(26);
            } else if (status === 'Max Regist') {
                setStep(27);
            }
        } catch (error) {
            console.error('API call failed:', error);
        }
    };

    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            <div className={`background-container ${windowHeight < 620 ? 'background-container-small' : 'background-container'}`}>
                <div className="portal-body">
                    <div className="portal-left">
                        <div className="brand-info">
                            <div className="portal-logo-brand">
                                <img src={nbtcLogo} className='portal-logo'></img>
                                <img src={depLogo} className='portal-logo' style={{ marginLeft: '20px' }}></img>
                                <img src={infiniteLogo} className='portal-logo' style={{ marginLeft: '20px' }}></img>
                                {/* <div className="portal-brandName">
                                    Your <span style={{ fontWeight: '700' }}>brand</span>
                                </div> */}
                            </div>
                            <div className="head-content" style={{marginTop: '30px'}}>
                                <div className="brand-head-sub">
                                    <div className="brand-heading">
                                        โครงการจัดให้มีบริการ
                                    </div>
                                    <div className="brand-subtitle">
                                        <div>อินเทอร์เน็ตความเร็วสูง</div>
                                        <div>สำหรับคนพิการ</div>
                                    </div>
                                </div>
                                {/* <div className="brand-content">
                                    <span style={{ fontWeight: 'bold' }}>Content</span>  &nbsp;&nbsp;
                                    lorem Ipsum is simply
                                    <br />text of the printing and typesetting <br />
                                    industry. Lorem Ipsum has when an <br />
                                    unknown  Lorem Ipsum has when...
                                </div>
                                <div className="mobile-brand-content">
                                    <span style={{ fontWeight: 'bold' }}>Content</span>  &nbsp;&nbsp;
                                    lorem Ipsum is simply
                                    text of the printing and typesetting
                                    industry. Lorem Ipsum has when an
                                    unknown  Lorem Ipsum has when...
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className={`portal-right ${windowHeight < 650 ? 'portal-right-small' : 'portal-right'}`}>
                        <div className={`button-con ${windowHeight < 650 ? 'button-con-small' : 'button-con'}`}>
                            <button onClick={handleCampaignStatus} className={`register-button ${windowHeight < 650 ? 'register-button-small' : 'register-button'}`}>
                                <img src={registerLogo} className={`register-logo ${windowHeight < 650 ? 'register-logo-small' : 'register-logo'}`} />
                                ลงทะเบียนรับซิมฟรี
                            </button>
                            <button onClick={() => setStep(9)} className={`status-button ${windowHeight < 650 ? 'status-button-small' : 'status-button'}`}>
                                <img src={statusLogo} className={`status-logo ${windowHeight < 650 ? 'status-logo-small' : 'status-logo'}`} />
                                เช็คผลการลงทะเบียน
                            </button>
                        </div>
                        <div className='remarkDetail'>
                            <center>ผู้มีสิทธิเข้าร่วมโครงการ</center>
                            ** คนพิการที่มีบัตรประจำตัวคนพิการ (ที่ยังไม่หมดอายุ)<br/>
                            ** มีบัตรสวัสดิการแห่งรัฐ
                        </div>
                    </div>
                    
                </div>
                {/* <PortalFooter className="portal-footer"/> */}
            </div>
        </>
    );
}

export default PortalPage;
import React, { useContext, useState, useEffect } from 'react';
import { multiStepContext } from '../StepContext';
import '../Styles/CompleteStatus.css';
import completeStatus from '../assets/complete-status.png';
import Axios from 'axios';
import moment from 'moment';
import 'moment/locale/th';

const CompleteStatusPage = () => {
  const apiURL = process.env.REACT_APP_API_URL;
  const { setStep, userData, setUserData, submitData } = useContext(multiStepContext);
  // const [isPopupVisible, setIsPopupVisible] = useState(userData.simMethod === 'mail');
  // const [popupDateTypeOne, setPopupDateTypeOne] = useState({ startDate: '', endDate: '' });
  // const [popupDateTypeTwo, setPopupDateTypeTwo] = useState({ startDate: '', endDate: '' });

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [disabledData, setDisabledData] = useState(null)
  const [expectedDate, setExpectedDate] = useState(null);

  useEffect(() => {
    const getAddressData = async () => {
      try {
        const response = await Axios.get(apiURL + `getPersonSubmitDate/${userData.statusId}`);
        setDisabledData(response.data.result)
        const retrievedDate = response.data.result.createDate;
        let newDate = moment(retrievedDate).add(10, 'days');
        newDate = moment(newDate).add(543, 'years').format('D MMMM YYYY');
        setExpectedDate(newDate);
      } catch (error) {
        console.error('API call failed:', error);
      }
    };

    getAddressData();
  }, []);

  const renderMessage = () => {
    if (!expectedDate) return null;
    
    if (disabledData.address_id) {
      return `ซิมการ์ดจะเริ่มจัดส่งให้ท่าน ตั้งแต่วันที่ 15 มกราคม 2568 เป็นต้นไป `;
    } else if (disabledData.branch_id || disabledData.disabledBranch_id) {
      return `ท่านสามารถเข้ารับซิมการ์ดได้ ตั้งแต่วันที่ 15 มกราคม 2568 เป็นต้นไป`;
    } else {
      return `แพ็คเกจของท่านสามารถใช้งานได้ตั้งแต่วันที่ 1 กุมภาพันธ์ 2568 เป็นต้นไป`;
    }
  };

  // const getPopupDate = async () => {
  //   try {
  //     const response = await Axios.get(apiURL + 'campaignReceiveSimDisable');
  //     const status = response.data.status;
  //     const branchDelivery = status.find(item => item.deliveryType_id === 1);
  //     const mailDelivery = status.find(item => item.deliveryType_id === 2);
  //     if (branchDelivery) {
  //       const { startTime, endTime } = branchDelivery;
  //       setPopupDateTypeOne({
  //         startDate: new Date(startTime).toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric' }),
  //         endDate: new Date(endTime).toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric' }),
  //       });
  //     }
  //     if (mailDelivery) {
  //       const { startTime, endTime } = mailDelivery;
  //       setPopupDateTypeTwo({
  //         startDate: new Date(startTime).toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric' }),
  //         endDate: new Date(endTime).toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric' }),
  //       });
  //     }
  //   } catch (error) {
  //     console.error('Error fetching popup date:', error);
  //   }
  // };

  // useEffect(() => {
  //   getPopupDate();
  // }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // const closeInfoPopup = () => {
  //   setIsPopupVisible(false);
  // };

  // const renderCompleteText = () => {
  //   if (userData.simMethod === 'mail') {
  //     return `ซิมการ์ดจะเริ่มจัดส่งให้ท่าน ตั้งแต่วันที่ ${popupDateTypeTwo.startDate} ถึง ${popupDateTypeTwo.endDate}`;
  //   } else if (userData.simMethod === 'branch') {
  //     return `ท่านสามารถเข้ารับซิมการ์ดได้ ตั้งแต่วันที่ ${popupDateTypeOne.startDate} ถึง ${popupDateTypeOne.endDate}`;
  //   } else {
  //     return null;
  //   }
  // };

  return (
    <div className="complete-container">
      <div className='complete-status'>
        ดําเนินการเสร็จสิ้น
        <br />
        <span style={{ fontSize: '16' }}>Completed!</span>
      </div>
      <div className="complete-img-container">
        <img src={completeStatus} className={`notFound-img ${windowHeight < 650 ? 'hidden-notFound' : 'notFound-img'}`}></img>
      </div>
      <div className="complete-text">
        {/* {renderCompleteText()} */}
        {renderMessage()}
      </div>
      <div className="complete-status-con">
        <button onClick={() => { submitData(); setStep(1); }} className="complete-status-bt">กลับสู่หน้าหลัก</button>
      </div>
      {/* {isPopupVisible && (
        <div className="popup-overlay">
          <div className="popup-window">
            <div className="popup-header">
              <span className="popup-title">ข้อมูลเพิ่มเติม</span>
              <button className="popup-close-btn" onClick={closeInfoPopup}>
                &times;
              </button>
            </div>
            <div className="popup-content">
              หากไม่สะดวกรับในวันที่จัดส่งซิมการ์ด ทางไปรษณีย์ไทยจะเก็บไว้ที่สถานให้บริการ 7 วัน
            </div>
          </div>
        </div>
      )} */}
    </div>
  )
}

export default CompleteStatusPage
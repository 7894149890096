import React, { useContext, useState, useEffect } from 'react';
import { multiStepContext } from '../StepContext';
import '../Styles/NotApprovedPage.css';
import NotapproveIcon from '../assets/fail-icon.png';

const NotApprovedPage = () => {
  const { setStep, userData, setUserData } = useContext(multiStepContext);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [titleText, setTitleText] = useState(`ท่านไม่ได้รับสิทธิ \n\n เนื่องจากท่านไม่เข้าเงื่อนไขการเข้าร่วมโครงการ`);
  const [infoText, setInfoText] = useState('');
  const [bulletPoints, setBulletPoints] = useState([]);
  const [introText, setIntroText] = useState('');
  const [closingText, setClosingText] = useState('');

  const invalidStatuses = ['Out', 'Normal', 'Well', 'Cancel', 'NoCard', 'IsNot', 'IncFLName'];
  const passIfStatuses = ['Wait', 'Expire', 'Caution', 'NoRequest', 'NoComplete'];


  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (userData.statusSubType) {
      if (invalidStatuses.includes(userData.statusSubType)) {
        setTitleText('คุณไม่มีสิทธิเข้าร่วมโครงการ');
        switch (userData.statusSubType) {
          case 'Out':
            setInfoText('เนื่องจาก : คนพิการถึงแก่ความตาย');
            break;
          case 'Normal':
            setInfoText('เนื่องจาก : ได้รับการฟื้นฟูจนไม่มีสภาพความพิการ');
            break;
          case 'Well':
            setInfoText('เนื่องจาก : ยังไม่เข้าเกณฑ์คนพิการ');
            break;
          case 'Cancel':
            setInfoText('เนื่องจาก : บัตรถูกยกเลิก');
            break;
          case 'NoCard':
            setInfoText('เนื่องจาก : ไม่ประสงค์มีบัตรประจำตัวคนพิการ');
            break;
          case 'IsNot':
            setInfoText('เนื่องจาก : ไม่ได้เป็นคนพิการ');
            break;
          case 'IncFLName':
            setInfoText('เนื่องจาก : ชื่อนามสกุลไม่ตรงกับกรมการปกครอง');
            break;
          default:
            setInfoText('');
        }
      } else if (passIfStatuses.includes(userData.statusSubType)) {
        setTitleText('คุณยังไม่สามารถลงทะเบียนโครงการได้');
        switch (userData.statusSubType) {
          case 'Wait':
            setInfoText('เนื่องจาก : อยู่ระหว่างการอนุมัติจากนายทะเบียนและพิมพ์บัตร');
            setBulletPoints([]);
            setIntroText('การดำเนินการ : กรุณาติดต่อศูนย์บริการคนพิการจังหวัด');
            setClosingText('');
            break;
          case 'Expire':
            setInfoText('เนื่องจาก : บัตรคนพิการหมดอายุ');
            setBulletPoints([
              'บัตรประชาชน',
              'ทะเบียนบ้าน (ถ้ามี)',
              'เอกสารรับรองความพิการ (กรณีมีความพิการเพิ่มหรือเปลี่ยนไปจากเดิม)',
              'หนังสือสำคัญแสดงการเปลี่ยนชื่อตัว หรือชื่อชื่อสกุล (ถ้ามี)',
              'บัตรประจำตัวคนพิการใบเดิม หรือเอกสารรับรองความพิการ',
            ]);
            setIntroText('การดำเนินการ : กรุณาติดต่อทำบัตรฯ ที่ศูนย์บริการคนพิการจังหวัดทุกแห่งทั่วประเทศ โดยมีรายละเอียด ดังนี้');
            setClosingText('เมื่อดำเนินการทำบัตรประจำตัวคนพิการแล้ว สามารถมาลงทะเบียนรับสิทธิใหม่ได้');
            break;
          case 'Caution':
            setInfoText('เนื่องจาก : ข้อมูลในระบบบัตรประจำตัวคนพิการไม่สมบูรณ์');
            setBulletPoints([
              'บัตรประชาชน',
              'ทะเบียนบ้าน (ถ้ามี)',
              'เอกสารรับรองความพิการ (กรณีมีความพิการเพิ่มหรือเปลี่ยนไปจากเดิม)',
              'หนังสือสำคัญแสดงการเปลี่ยนชื่อตัว หรือชื่อชื่อสกุล (ถ้ามี)',
              'บัตรประจำตัวคนพิการใบเดิม หรือเอกสารรับรองความพิการ',
            ]);
            setIntroText('การดำเนินการ : กรุณามายืนยันตัวตนเพื่อทำบัตรประจำตัวคนพิการใหม่ที่ศูนย์บริการคนพิการจังหวัดทุกแห่งทั่วประเทศ โดยมีรายละเอียด ดังนี้');
            setClosingText('เมื่อดำเนินการทำบัตรประจำตัวคนพิการแล้ว สามารถมาลงทะเบียนรับสิทธิใหม่ได้');
            break;
          case 'NoRequest':
            setInfoText('เนื่องจาก : ข้อมูลในระบบบัตรประจำตัวคนพิการไม่สมบูรณ์');
            setBulletPoints([
              'บัตรประชาชน',
              'ทะเบียนบ้าน (ถ้ามี)',
              'เอกสารรับรองความพิการ (กรณีมีความพิการเพิ่มหรือเปลี่ยนไปจากเดิม)',
              'หนังสือสำคัญแสดงการเปลี่ยนชื่อตัว หรือชื่อชื่อสกุล (ถ้ามี)',
              'บัตรประจำตัวคนพิการใบเดิม หรือเอกสารรับรองความพิการ',
            ]);
            setIntroText('การดำเนินการ : กรุณามายืนยันตัวตนเพื่อทำบัตรประจำตัวคนพิการใหม่ที่ศูนย์บริการคนพิการจังหวัดทุกแห่งทั่วประเทศ โดยมีรายละเอียด ดังนี้');
            setClosingText('เมื่อดำเนินการทำบัตรประจำตัวคนพิการแล้ว สามารถมาลงทะเบียนรับสิทธิใหม่ได้');
            break;
          case 'NoComplete':
            setInfoText('เนื่องจาก : ข้อมูลในระบบบัตรประจำตัวคนพิการไม่สมบูรณ์');
            setBulletPoints([
              'บัตรประชาชน',
              'ทะเบียนบ้าน (ถ้ามี)',
              'เอกสารรับรองความพิการ (กรณีมีความพิการเพิ่มหรือเปลี่ยนไปจากเดิม)',
              'หนังสือสำคัญแสดงการเปลี่ยนชื่อตัว หรือชื่อชื่อสกุล (ถ้ามี)',
              'บัตรประจำตัวคนพิการใบเดิม หรือเอกสารรับรองความพิการ',
            ]);
            setIntroText('การดำเนินการ : กรุณามายืนยันตัวตนเพื่อทำบัตรประจำตัวคนพิการใหม่ที่ศูนย์บริการคนพิการจังหวัดทุกแห่งทั่วประเทศ โดยมีรายละเอียด ดังนี้');
            setClosingText('เมื่อดำเนินการทำบัตรประจำตัวคนพิการแล้ว สามารถมาลงทะเบียนรับสิทธิใหม่ได้');
            break;
          default:
            setInfoText('');
            setBulletPoints([]);
            setIntroText('');
            setClosingText('');
        }
      }
    }
  }, [userData.statusSubType]);

  return (
    <div className="not-container">
      <div className='not-info'>
        {titleText}
        <br />
        <span style={{ fontSize: '16' }}>You are not Eligible</span>
      </div>
      <div className="not-img-container">
        <img src={NotapproveIcon} className={`not-img ${windowHeight < 650 ? 'hidden-notFound' : 'not-img'}`}></img>
      </div>
      <div className="not-text">
        <strong style={{ fontSize: '18px' }}>{infoText}</strong>
        {introText && (
          <div>
            <br />
            <strong>{introText}</strong>
            {bulletPoints.length > 0 && (
              <ul>
                {bulletPoints.map((point, index) => (
                  <li key={index}>{point}</li>
                ))}
              </ul>
            )}
            {closingText && <span>{closingText}</span>}
          </div>
        )}
      </div>
      <div className="not-register-con" style={{ paddingBottom: '20px' }}>
        <button onClick={() => setStep(1)} className="not-register-bt">กลับสู่หน้าหลัก</button>
      </div>
    </div>
  )
}

export default NotApprovedPage;